<template>
  <div>
    <v-text-field
      label="Firstname"
      v-model="innerFirstName"
      @keyup="$emit('update:firstName', innerFirstName)"
    >
    </v-text-field>
    <v-text-field
      label="Lastname"
      v-model="innerLastName"
      @keyup="$emit('update:lastName', innerLastName)"
    >
    </v-text-field>
    <v-text-field
      label="Job Title"
      v-model="innerJobTitle"
      @keyup="$emit('update:jobTitle', innerJobTitle)"
    >
    </v-text-field>
    <v-text-field
      label="Emailaddress"
      v-model="innerEmailAddress"
      @keyup="$emit('update:emailAddress', innerEmailAddress)"
    >
    </v-text-field>
    <v-switch
      label="Is Thomas"
      v-model="innerIsAdmin"
      @click="$emit('update:isAdmin', innerIsAdmin)"
    ></v-switch>
  </div>
</template>

<script>
export default {
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    jobTitle: {
      type: String,
      default: ''
    },
    emailAddress: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    firstName(){
      this.innerFirstName = this.firstName;
    },
    lastName(){
      this.innerLastName = this.lastName;
    },
    jobTitle(){
      this.innerJobTitle = this.jobTitle;
    },
    emailAddress(){
      this.innerEmailAddress = this.emailAddress;
    },
    isAdmin(){
      this.innerIsAdmin = this.isAdmin;
    }
  },
  data() {
    return {
      innerFirstName: "",
      innerLastName: "",
      innerJobTitle: "",
      innerEmailAddress: "",
      innerIsAdmin: "",
    };
  },
};
</script>

<style>
</style>