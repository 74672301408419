<template>
  <trev-system-user-add></trev-system-user-add>
</template>

<script>
import trevSystemUserAdd from '../../../../components/systemusers/trev-system-user-add.vue'
export default {
  components: { trevSystemUserAdd },

}
</script>

<style>

</style>