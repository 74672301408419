<template>
  <div>
    <v-stepper v-model="stepperStep">
      <v-stepper-header>
        <v-stepper-step :complete="stepperStep > 1" step="1">
          Basic Details
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepperStep > 2" step="2">
          Roles
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3"> Finalise </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <trev-system-user-basic-details
            :firstName.sync="systemUser.firstName"
            :lastName.sync="systemUser.lastName"
            :jobTitle.sync="systemUser.jobTitle"
            :emailAddress.sync="systemUser.emailAddress"
            :isAdmin.sync="systemUser.isAdmin"
          >
          </trev-system-user-basic-details>
          <v-btn color="primary" class="float-right" @click="stepperStep++">
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container fluid>
            <v-row>
              <v-col>
                
              </v-col>
              <v-col>
                <trev-cool-list
                  title="Selected Roles"
                  titleIcon="mdi-check"
                  :items="systemUser.roles"
                >
                  <template v-slot:repeaterItem="item">
                    <v-list-item-content>
                      {{ item.item.name }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="deselectedRole(item.item)"
                        small
                        color="danger"
                      >
                        <v-icon> mdi-delete </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </trev-cool-list>
              </v-col>
            </v-row>
          </v-container>
          <v-btn color="primary" class="float-right" @click="stepperStep++">
            Next
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <base-card>
                  <v-toolbar>
                    <v-toolbar-title>
                      <v-icon class="mr-2"> mdi-asterisk </v-icon>
                      New user
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        {{ systemUser.jobTitle }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        {{ systemUser.firstName + " " + systemUser.lastName }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        {{ systemUser.emailAddress }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="systemUser.isAdmin">
                      <v-list-item-icon>
                        <v-icon color="success"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content> Is an Admin </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else>
                      <v-list-item-icon>
                        <v-icon color="danger"> mdi-check </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Is not an Admin
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </base-card>
              </v-col>
            </v-row>
          </v-container>
          <v-btn color="success" class="float-right" @click="saveNewUser">
            Add New User
            <v-icon> mdi-save</v-icon>
          </v-btn>

          <v-btn color="primary" @click="stepperStep--" class="float-left">
            <v-icon> mdi-chevron-left </v-icon>
            Previous
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import TrevCoolList from "../tables/trev-cool-list.vue";
import TrevSystemUserBasicDetails from "./forms/trev-system-user-basic-details.vue";
export default {
  components: {
    TrevSystemUserBasicDetails,
    TrevCoolList,
  },
  methods: {
    saveNewUser(){
      this.$courier.SystemUsers.add(this.systemUser).then(x => {
        this.$router.push({
          name: 'View System User',
          params: { userId: x.id }
        })
      })
    }
  },
  data() {
    return {
      stepperStep: 1,
      systemUser: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        emailAddress: "",
        isAdmin: false,
        roles: [],
      },
    };
  },
};
</script>

<style>
</style>